import bgList from './bg-list.json';
import linkList from './link-list.json';
import {buildLinks} from './buildLinks';

const bgImg = bgList[Math.floor(Math.random() * bgList.length)];

export function App() {
  const boxStyle = {
    background: `url(${bgImg})`
  };

  const socialLinks = buildLinks(linkList, 'social');

  const localLinks = buildLinks(linkList, 'local');

  return (
    <div id='body'>
      <span id='header'>kittylitter.net</span>
      <div id='box' style={boxStyle}>
        <div id='link-box'>
          <div id='transparency' />
          <ul id='social-links'>{socialLinks}</ul>
          <ul id='local-links'>{localLinks}</ul>
        </div>
      </div>
      <span id='footer'>Welcome to the litterbox</span>
    </div>
  );
};


