
export function buildLinks(list, filter) {
  return list
    .filter(_ => _.type === filter)
    .map(_ => {
      return (
        <li key={_.name}>
          <a href={_.url}>
            <img src={_.icon} height='32' width='32' alt='' />
            {_.name}
          </a>
        </li>
      );
    });
};

