[
  {
    "type": "social",
    "name": "Facebook",
    "url": "https://www.facebook.com/liz.fahy.scott",
    "icon": "/img/facebook-32.png"
  },
  {
    "type": "social",
    "name": "Instagram",
    "url": "https://www.instagram.com/lizard.bits.25/",
    "icon": "/img/instagram-32.png"
  },
  {
    "type": "social",
    "name": "LinkedIn",
    "url": "https://www.linkedin.com/in/lizscott",
    "icon": "/img/linkedin-32.png"
  },
  {
    "type": "social",
    "name": "LiveJournal",
    "url": "https://kittylitter1.livejournal.com/",
    "icon": "/img/livejournal-32.png"
  },
  {
    "type": "social",
    "name": "Youtube",
    "url": "https://www.youtube.com/user/kittylitter1111",
    "icon": "/img/youtube-32.png"
  },
  {
    "type": "local",
    "name": "Photo Gallery",
    "url": "https://theadventuresoflizandgary.com/",
    "icon": "/img/picture-32.png"
  },
  {
    "type": "local",
    "name": "Tiki Cat Lounge",
    "url": "https://tikicatlounge.com/",
    "icon": "/img/tikicat-32.png"
  },
  {
    "type": "local",
    "name": "Contact",
    "url": "mailto:kittylitter1@gmail.com",
    "icon": "/img/mail-32.png"
  }
]
